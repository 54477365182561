<template>
    <v-card width="100%" height="100%" v-if="loaded">
        <v-tabs v-model="currentTab" show-arrows centered>
            <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->

            <v-tab v-for="(item, index) in categories" :key="index">
                {{ item.Name }}
            </v-tab>
        </v-tabs>
        <v-row
            v-if="filteredItems.length === 0"
            style="height:50%"
            class="ma-2"
            justify="center"
            align-content="center"
        >
            <v-alert border="right" colored-border type="error" elevation="2">
                Nessun risultato
            </v-alert>
        </v-row>
        <PromosList
            :promos="filteredItems"
            :scrollerHeight="scrollerHeight - marginTop"
            @selected="selectPromo"
        ></PromosList>
    </v-card>
</template>

<script>
export const PromosList = () => import("./PromosList");

import { compareDate } from "../../utils/utils";

import { mapActions, mapMutations, mapState } from "vuex";
import * as farmPromosActions from "../../store/actions/farmPromos";
import * as promoCategoryActions from "../../store/actions/promoCategory";
import * as appActions from "../../store/actions/app";

export default {
    props: ["id", "scrollerHeight"],

    data: () => ({
        // scrollerHeight: 0,
        hidden: false
    }),

    components: {
        PromosList
    },

    computed: {
        ...mapState({
            promoCategories: state => state.promoCategoryModule.categories,
            farmsPromos: state => state.farmPromosModule.promos,
            loading: state => state.farmPromosModule.loading,
            loaded: state => state.farmPromosModule.loaded,
            error: state => state.farmPromosModule.error,
            promoFilter: state => state.filterModule.promoFilter,
            currentTabPosition: state => state.appModule.farmPromosCurrentTab
        }),

        currentTab: {
            get: function() {
                return this.currentTabPosition;
            },
            set: function(newValue) {
                this.setTabPosition(newValue);
            }
        },

        categories() {
            var cats = JSON.parse(JSON.stringify(this.promoCategories));
            cats.unshift({ Name: "Tutte", Id: "0" });
            return cats;
        },

        filteredItems() {
            console.log(this.farmsPromos);
            let dt;
            dt = new Date();
            dt.setHours(0, 0, 0, 0);
            return this.farmsPromos.filter(promo => {
                const expired = compareDate(promo.FineValidita) === 1 || compareDate(promo.FinePubblicazione) === 1;
                const toPublish = compareDate(promo.InizioValidita) === -1 || compareDate(promo.InizioPubblicazione) === -1;
                const deleted = promo.Action === 1;
                const suspended = promo.Sospeso === true;
                const active =
                    !expired &&
                    !toPublish &&
                    !deleted &&
                    !suspended &&
                    promo.Action !== 1;

                const f1 = this.promoFilter.expired && expired;
                const f2 = this.promoFilter.deleted && deleted;
                const f3 = this.promoFilter.toPublish && toPublish;
                const f4 = this.promoFilter.suspended && suspended;
                const f5 = this.promoFilter.active && active;
                const f6 =
                    this.promoFilter.text.length === 0
                        ? true
                        : promo.Titolo.toLowerCase().indexOf(
                              this.promoFilter.text.toLowerCase()
                          ) !== -1;

                const f7 = this.promoFilter.fromEnabled
                    ? new Date(promo.InizioValidita).setHours(0, 0, 0, 0) >=
                      new Date(this.promoFilter.from).setHours(0, 0, 0, 0)
                    : true;

                const f8 = this.promoFilter.toEnabled
                    ? new Date(promo.FineValidita).setHours(0, 0, 0, 0) <=
                      new Date(this.promoFilter.to).setHours(0, 0, 0, 0)
                    : true;

                const f9 = this.currentTab === 0
                        ? true
                        : promo.Type === this.promoCategories[this.currentTab - 1].IdCategory

                return (f1 || f2 || f3 || f4 && !expired || f5) && f6 && f7 && f8 && f9;
            });
        },

        marginTop() {
            return "48";
        }
    },

    methods: {
        ...mapActions({
            getFarmPromos: farmPromosActions.FARMPROMOS_FETCH_PROMOS,
            getCategories: promoCategoryActions.PROMOCATEGORY_GETCATEGORIES
        }),
        ...mapMutations({
            setTabPosition: appActions.APP_SETFARMPROMOS_CURRENTTAB
        }),

        // onResize() {
        //     this.$nextTick(function() {
        //         const vw = Math.max(
        //             document.documentElement.clientWidth || 0,
        //             window.innerWidth || 0
        //         );
        //         const vh = Math.max(
        //             document.documentElement.clientHeight || 0,
        //             window.innerHeight || 0
        //         );
        //         this.scrollerHeight = vh - this.$vuetify.application.top - 72;
        //         /*                 console.log(vw, vh);
        //         console.log(this.$vuetify); */
        //     });
        // },
        selectPromo(promo) {
            this.$router
                .push({
                    name: "Promo",
                    params: { promoId: promo }
                })
                .catch(() => {});
        }
    },

    beforeMount() {
        this.getCategories(this.id);
    },

    mounted() {
        this.$nextTick(function() {
            this.getFarmPromos(this.id);
        });
    }
};
</script>

<style scoped></style>
